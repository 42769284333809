import axios, { AxiosInstance } from "axios";
import { useAuthContext } from "./useAuthenticate";
import { useEnvVarsContext } from "./useEnvironment";
import { useMidwayToken } from "./useMidwayToken";

/**
 * @returns an axios instance with backend and authorization set.
 */
export default function useApi(): AxiosInstance {
  const { jwtToken } = useAuthContext();
  const { API_ENDPOINT } = useEnvVarsContext();
  const { midwayToken } = useMidwayToken();

  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      AUTHORIZATION: jwtToken,
      MIDWAY_TOKEN: midwayToken || "",
    },
    withCredentials: true,
  });
}
