import { useState, useEffect } from "react";
import getMidwayToken from "../authentication/getMidwayToken";

export function useMidwayToken() {
  const [midwayToken, setMidwayToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchMidwayToken = async () => {
      const midwayToken = await getMidwayToken();
      setMidwayToken(midwayToken);
    };
    fetchMidwayToken();
  }, []);

  return { midwayToken };
}
