enum Month {
    January = 0,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}

function getCommemorativeEmoji(): string {
    const currentMonth = new Date().getMonth();

    switch (currentMonth) {
        case Month.January:
            return '🥂 '; // New Year's celebration
        case Month.February:
            return '💘 '; // Valentine's Day
        case Month.April:
            return '🐰 '; // Easter
        case Month.October:
            return '🎃 '; // Halloween
        case Month.December:
            return '🎄 '; // Christmas
        default:
            return '';
    }
}

export default getCommemorativeEmoji;