export const MIDWAY_DOMAIN = "midway-auth.amazon.com";

export function generateNonce() {
  let nonce = "";
  const characterSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 64; i += 1) {
    nonce += characterSet.charAt(Math.floor(Math.random() * characterSet.length));
  }
  return nonce;
}

function buildQuery(parameters: SSOQueryParameters) {
  return Object.entries(parameters).reduce((accumulator, [key, value]) => `${accumulator + key}=${value}&`, "?");
}

interface SSOQueryParameters {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
  nonce: string;
}

function buildBaseQueryParams(): SSOQueryParameters {
  return {
    response_type: "id_token",
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    scope: "openid",
    nonce: generateNonce(),
  };
}

function buildSSOUrl(): string {
  return `https://${MIDWAY_DOMAIN}/SSO${buildQuery(buildBaseQueryParams())}`;
}

function buildRedirectUrl(): string {
  const queryString = buildQuery(buildBaseQueryParams());
  return `https://${MIDWAY_DOMAIN}/login?next=/SSO/redirect${encodeURIComponent(queryString)}`;
}

const getMidwayToken = () =>
  fetch(buildSSOUrl(), { credentials: "include" }).then(async (response) => {
    if (response.ok) {
      const midwayToken = await response.text();
      return midwayToken;
    }
    window.location.href = buildRedirectUrl();
    throw new Error("Error fetching midway token required for transitive auth");
  });

export default getMidwayToken;
