/* eslint-disable react/prop-types */
import React from "react";
import { Box, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { BusinessItem } from "./types";

interface OperationStatsProps {
  items: BusinessItem[];
  operationType: "enrol" | "unenrol";
}

const OperationStats: React.FC<OperationStatsProps> = React.memo(({ items, operationType }) => {
  const total = items.length;
  const successful = items.filter(
    (item: BusinessItem) => item.status.toUpperCase() === (operationType === "enrol" ? "ENROLLED" : "UNENROLLED")
  ).length;
  const failed = items.filter((item: BusinessItem) => item.status.toUpperCase() === "FAILED").length;

  const successPercentage = (successful / total) * 100;
  const failedPercentage = (failed / total) * 100;

  return (
    <Box padding="l">
      <SpaceBetween direction="vertical" size="s">
        <Box>
          <strong>Total Businesses: {total}</strong>
        </Box>
        <Box>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ width: "200px" }}>Successful: {successful}</div>
            <div
              style={{
                flex: 1,
                height: "24px",
                background: "#eaeded",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: `${successPercentage}%`,
                  height: "100%",
                  backgroundColor: "#037f0c",
                  transition: "width 0.3s ease",
                }}
              />
            </div>
            <div style={{ minWidth: "60px" }}>{successPercentage.toFixed(1)}%</div>
          </div>
        </Box>
        <Box>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ width: "200px" }}>Failed: {failed}</div>
            <div
              style={{
                flex: 1,
                height: "24px",
                background: "#eaeded",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: `${failedPercentage}%`,
                  height: "100%",
                  backgroundColor: "#d13212",
                  transition: "width 0.3s ease",
                }}
              />
            </div>
            <div style={{ minWidth: "60px" }}>{failedPercentage.toFixed(1)}%</div>
          </div>
        </Box>
      </SpaceBetween>
    </Box>
  );
});

OperationStats.displayName = "OperationStats";

export default OperationStats;
